import { Skeleton, Stack } from "@mui/material";

export function PlacementsLoader() {
  return (
    <Stack direction="column" spacing={2}>
      {Array.from({ length: 3 }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={index} variant="rounded" width="100%" height={205} />
      ))}
    </Stack>
  );
}
