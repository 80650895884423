import { CardWithIntent } from "@clipboard-health/ui-components";
import { LoadingButton, Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Button";
import {
  type OnboardingRequirement,
  OnboardingRequirementStatuses,
} from "../../PlacementCandidate/hooks/useOnboardingRequirements";
import { PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH } from "../../PlacementCandidate/PlacementCandidateModalRoutes";

export const PLACEMENT_ACTION_TYPE = {
  BOOK_INTERVIEW: "BOOK_INTERVIEW",
  RESCHEDULE_INTERVIEW: "RESCHEDULE_INTERVIEW",
  APPLY: "APPLY",
  COMPLETE_PROFILE: "COMPLETE_PROFILE",
  UPLOAD_LICENSE: "UPLOAD_LICENSE",
} as const;

export type PlacementActionType =
  (typeof PLACEMENT_ACTION_TYPE)[keyof typeof PLACEMENT_ACTION_TYPE];

export interface PrimaryActionProps {
  type: PlacementActionType;
  label: string;
  warning?: string;
  description?: string;
  onClick: () => void;
  disabled: boolean;
  isLoading: boolean;
}

interface PlacementPrimaryActionProps {
  isProspectWorkplace: boolean;
  primaryActionProps: PrimaryActionProps;
  isProfilePending: boolean;
  requirements: OnboardingRequirement[];
}

export function PlacementPrimaryAction(props: PlacementPrimaryActionProps) {
  const history = useHistory();
  const { isProspectWorkplace, primaryActionProps, isProfilePending, requirements } = props;

  switch (primaryActionProps.type) {
    case PLACEMENT_ACTION_TYPE.UPLOAD_LICENSE: {
      return (
        <>
          {primaryActionProps.warning && (
            <Text variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
              {primaryActionProps.warning}
            </Text>
          )}
          <Button fullWidth variant="contained" size="large" onClick={primaryActionProps.onClick}>
            {primaryActionProps.label}
          </Button>
        </>
      );
    }

    case PLACEMENT_ACTION_TYPE.COMPLETE_PROFILE: {
      const allPending =
        requirements.filter(
          (requirement) => requirement.status === OnboardingRequirementStatuses.IN_PROGRESS
        ).length === requirements.length;
      return isProfilePending ? (
        <CardWithIntent intent="neutral" sx={{ padding: 6 }}>
          <Stack direction="row" spacing={6} alignItems="center">
            <Stack spacing={2}>
              <Text sx={{ fontWeight: 500 }}>
                {allPending
                  ? `Your documents are in review`
                  : `Your ${
                      requirements.find(
                        (requirement) =>
                          requirement.status === OnboardingRequirementStatuses.IN_PROGRESS
                      )?.name ?? "document"
                    } is in review`}
              </Text>
              <Text sx={{ color: (theme) => theme.palette.text.secondary }}>
                {primaryActionProps.description}
              </Text>
            </Stack>
            <Button
              variant="outlined"
              sx={{ width: "128px", flexBasis: "128px" }}
              onClick={() => {
                logEvent(APP_V2_APP_EVENTS.PLACEMENT_DETAILS_PAGE_ACTIONS, {
                  action: "complete-profile-clicked",
                  isProfilePending,
                  requirements,
                });
                history.push(PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH);
              }}
            >
              {primaryActionProps.label}
            </Button>
          </Stack>
        </CardWithIntent>
      ) : (
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            logEvent(APP_V2_APP_EVENTS.PLACEMENT_DETAILS_PAGE_ACTIONS, {
              action: "complete-profile-clicked",
              isProfilePending,
              requirements,
            });
            primaryActionProps.onClick();
          }}
        >
          {primaryActionProps.label}
        </Button>
      );
    }

    case PLACEMENT_ACTION_TYPE.BOOK_INTERVIEW: {
      return (
        <Stack spacing={5}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            disabled={primaryActionProps.disabled}
            onClick={primaryActionProps.onClick}
          >
            {primaryActionProps.label}
          </Button>
          <Stack spacing={1}>
            <Text variant="body1" textAlign="center">
              You&apos;ll receive $20 for travel expenses once
            </Text>
            <Text variant="body1" textAlign="center">
              your {isProspectWorkplace ? "walk-in" : "interview"} is completed
            </Text>
          </Stack>
        </Stack>
      );
    }

    case PLACEMENT_ACTION_TYPE.APPLY: {
      return (
        <>
          {primaryActionProps.warning && (
            <Text variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
              {primaryActionProps.warning}
            </Text>
          )}
          <LoadingButton
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            isLoading={primaryActionProps.isLoading}
            disabled={primaryActionProps.disabled}
            onClick={primaryActionProps.onClick}
          >
            {primaryActionProps.label}
          </LoadingButton>
        </>
      );
    }

    case PLACEMENT_ACTION_TYPE.RESCHEDULE_INTERVIEW: {
      return (
        <Stack spacing={5}>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            disabled={primaryActionProps.disabled}
            onClick={primaryActionProps.onClick}
          >
            {primaryActionProps.label}
          </Button>
        </Stack>
      );
    }

    default: {
      return null;
    }
  }
}
